// src/components/Sidebar/Sidebar.js
import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { categoryLinks } from '../../utils/categoryLinks'; // Import common links

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import InquiriesIcon from '@mui/icons-material/QuestionAnswer'; // Example for another icon
import CategoryIcon from '@mui/icons-material/Category';

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true); // To handle menu toggle
  const [isSticky, setIsSticky] = useState(false); // To handle sticky state
  const location = useLocation(); // To get the current route
  const logoRef = useRef(null);
  const sidebarRef = useRef(null);

  const placeholderImage = 'https://via.placeholder.com/40'; // Placeholder image

  // Handle menu item active state
  const getLinkClass = (path) => {
    // Exact match for home link
    if (path === '/v1/portal/csutc/dashboard') {
      return location.pathname === path
      ? 'bg-[#0171BB] text-white border border-white rounded-md'
      : 'hover:bg-[#0171BB] hover:text-white hover:border border-white rounded-md';
     }
  
    // Prefix match for other links
    return location.pathname.startsWith(path)
    ? 'bg-[#0171BB] text-white border border-white rounded-md'
    : 'hover:bg-[#0171BB] hover:text-white hover:border border-white rounded-md';  
    };

  // Customize the scrollbar
  const scrollbarStyles = `
    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 4px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }
  `;

  // Handle scroll to make the logo sticky
  useEffect(() => {
    const handleScroll = () => {
      if (sidebarRef.current) {
        const scrollTop = sidebarRef.current.scrollTop;
        const threshold = 100; // Adjust this value as needed
        setIsSticky(scrollTop > threshold);
      }
    };

    const sidebarElement = sidebarRef.current;
    if (sidebarElement) {
      sidebarElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (sidebarElement) {
        sidebarElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  // Update isOpen based on the current location
  useEffect(() => {
    const currentPath = location.pathname;
    const isCategoryPath = categoryLinks.some(category =>
      currentPath.startsWith(`/v1/portal/csutc/dashboard/${category.link}`)
    );
    if (isCategoryPath) {
      setIsOpen(true); // Open categories if the path matches a category
    }
  }, [location.pathname]);

  return (
    <aside
     ref={sidebarRef}
    className={`bg-white text-black ${isOpen ? 'w-64' : 'w-64'} h-full z-40 fixed top-0 left-0 shadow-lg flex flex-col overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 px-1 py-1 transition-width duration-300`}
      >
        <style>{scrollbarStyles}</style>
      <div
        ref={logoRef}
        className={`p-4 flex-shrink-0 ${
          isSticky
            ? 'sticky top-[-16px] bg-white shadow-md z-[9999]'
            : ''
        }`}
      >
        <Link to="/v1/portal/csutc/dashboard">
          <img
            className="w-full" // Ensures full width usage within the padding box
            src="https://foxxbioprocess.myshopify.com/cdn/shop/files/Foxx_Logo_Tagline_0e42bd12-4dce-4fda-abf8-db37715e463d.png?v=1669752062&width=300"
            alt="Logo"
          />
        </Link>
      </div>
      <nav className="flex-grow p-2"> {/* Add padding to the nav */}
        <ul className="space-y-2">
        <li>
        <Link
          to="/v1/portal/csutc/dashboard"
          className={`flex items-center py-2 px-4 rounded-md ${getLinkClass('/v1/portal/csutc/dashboard')}`}
        >
          <div className="bg-gray-100 p-1 rounded-full mr-3 flex-shrink-0">
            <HomeIcon className="text-[#0171BB]" />
          </div>
          <span className="flex-1">Home</span>
        </Link>
      </li>
          <li>
           <button
          onClick={() => setIsOpen(!isOpen)}
          className={`w-full text-left py-2 px-4 hover:bg-[#0171BB] hover:text-white flex items-center border border-transparent rounded-md transition-colors duration-300`}
        >
          <div className="bg-gray-100 p-1 rounded-full mr-3 flex-shrink-0">
            <CategoryIcon className="text-[#0171BB]" />
          </div>
          Sets Categories
          <svg className={`ml-auto w-4 h-4 transform ${isOpen ? 'rotate-180' : ''}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
          </svg>
        </button>
         {isOpen && (
        <ul className="pl-4 space-y-2 mt-2 border-l-2 border-gray-300 relative">
          {categoryLinks.map((category) => (
            <li
              key={category.id}
              className="relative pl-4"
            >
              <Link
                to={`/v1/portal/csutc/dashboard/${category.link}`}
                className={`group flex items-center py-2 px-2 rounded-md ${getLinkClass(`/v1/portal/csutc/dashboard/${category.link}`)}`}
              >
                <div
                  className={`bg-gray-200 p-1 rounded-full mr-3 flex-shrink-0 border-2 ${location.pathname.startsWith(`/v1/portal/csutc/dashboard/${category.link}`) ? 'border-blue-[#0171BB]' : 'border-[#0171BB]'} group-hover:border-green-500 group-hover:bg-white`}
                >
                  <img
                    src={category.image || placeholderImage}
                    alt={category.name}
                    className="w-6 h-6 object-cover rounded-full"
                  />
                </div>
                <span className="flex-1">{category.name}</span>
              </Link>
              {/* Adding a horizontal border line */}
              <div
                className="absolute top-1/2 h-0.5 w-10 bg-gray-200 transform -translate-y-1/2"
                style={{ left: '-16px' }}
              />
            </li>
          ))}
          </ul>
          )}
          
          </li>
          <li className="mt-auto">
          <Link
            to="/v1/portal/csutc/dashboard/account-settings/profile"
            className={`flex items-center py-2 px-4 rounded-md ${getLinkClass('/v1/portal/csutc/dashboard/account-settings/profile')}`}
          >
            <div className="bg-gray-100 p-1 rounded-full mr-3 flex-shrink-0">
              <AccountCircleIcon className="text-[#0171BB]" />
            </div>
            <span className="flex-1">Account Settings</span>
          </Link>
        </li>

        <li className="mt-auto">
          <Link
            to="/v1/portal/csutc/dashboard/inquiries/submitted-inquiries"
            className={`flex items-center py-2 px-4 rounded-md ${getLinkClass('/v1/portal/csutc/dashboard/inquiries/submitted-inquiries')}`}
          >
            <div className="bg-gray-100 p-1 rounded-full mr-3 flex-shrink-0">
              <InquiriesIcon className="text-[#0171BB]" />
            </div>
            <span className="flex-1">Submitted Inquiries</span>
          </Link>
        </li>
{/* 
        <li className="mt-auto">
          <Link
            to="/v1/portal/csutc/dashboard/quinn-video"
            className={`flex items-center py-2 px-4 rounded-md ${getLinkClass('/v1/portal/csutc/dashboard/quinn-video')}`}
          >
            <div className="bg-gray-100 p-1 rounded-full mr-3 flex-shrink-0">
              <InquiriesIcon className="text-[#0171BB]" />
            </div>
            <span className="flex-1">Videos</span>
          </Link>
        </li> */}
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
